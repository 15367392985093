<template>
    <div
        class="login-box"
        :style="{
            '--coefficient': (loginPageInfo.loginBox.width / loginBoxOriginalWidth) + 'px' 
        }">
        <div :class="classes">
            <template v-if="hasUserName">
                <common-login
                    :templateInfo="templateInfo"
                    :loginPageInfo="loginPageInfo"
                    :loginForm="loginForm"
                    :loginRules="loginRules"
                    :loginButtonLoading="loginButtonLoading"
                    @forgottenPassword="forgottenPassword"
                >
                </common-login>
                <!-- 密码登录 -->
                <!-- <password-login
                    v-if="loginForm.curLoginMethod === '1'"
                    :templateInfo="templateInfo"
                    :loginForm="loginForm"
                    :loginRules="loginRules"
                    :loginButtonLoading="loginButtonLoading"
                    @forgottenPassword="forgottenPassword"
                ></password-login> -->
                <!-- 短信验证码登录 -->
                <!-- <verification-code-login
                    v-else-if="loginForm.curLoginMethod === '4'"
                    :templateInfo="templateInfo"
                    :loginForm="loginForm"
                    :loginRules="loginRules"
                    :loginButtonLoading="loginButtonLoading"
                ></verification-code-login> -->
                <!-- 扫码登录 -->
                <!-- <q-r-code-login
                    v-else-if="['2', '3'].includes(loginForm.curLoginMethod)"
                    :templateInfo="templateInfo"
                    :loginForm="loginForm"
                ></q-r-code-login> -->
            </template>
            <template v-else>
                <forgotten-password
                    :loginPageInfo="loginPageInfo"
                    :loginButtonLoading="loginButtonLoading"
                    @backLogin="backLogin"
                >
                </forgotten-password>
            </template>
        </div>
    </div>
</template>

<script>
import CommonLogin from '../common/CommonLogin.vue';
import PasswordLogin from "./PasswordLogin.vue"
import VerificationCodeLogin from "./VerificationCodeLogin.vue"
import QRCodeLogin from "./QRCodeLogin.vue"
import ForgottenPassword from './ForgottenPassword.vue';
import { themeList } from "@/assets/data/LoginPageTemplate/themeList.js"

export default {
    name: 'LoginBox1',
    components: {
        CommonLogin,
        PasswordLogin,
        VerificationCodeLogin,
        QRCodeLogin,
        ForgottenPassword
    },
    provide(){
        return {
            loginMain: this
        }
    },
    props: {
        templateInfo: {
            type: Object
        },
        loginPageInfo: {
            type: Object
        },
        loginForm: {
            type: Object
        },
        loginRules: {
            type: Object
        },
        loginButtonLoading: {
            type: Boolean
        }
    },
    data() {
        return {
            hasUserName: true,
            setBc: false,
        }
    },
    filters: {},
    computed: {
        loginBoxOriginalWidth() {
            return themeList.find(e => e.id === this.loginPageInfo.theme).loginBox.width
        },
        classes() {
            let cla = 'login-main';
            return [cla, {'bc': this.setBc}]
        }
    },
    watch: {},
    created() {
        this.$on('set-bc-mounted', () => {
            this.setBc = true;
        });
        this.$on('set-bc-unmounted', () => {
            this.setBc = false;
        });
    },
    mounted() {},
    methods: {
        forgottenPassword () {
            this.hasUserName = false;
            console.log('忘记密码-LoginBox1')
        },
        backLogin () {
            this.hasUserName = true;
        }
    }
}
</script>

<style lang="scss" scoped>
$coefficient: var(--coefficient, 1px);

.login-box {
    width: calc(100% * #{$coefficient});
    height: 100%;
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    box-sizing: border-box;
}

.login-main {
    width: 100%;
    height: 100%;
    background-color: transparent;
    border-radius: calc(10 * #{$coefficient});
    overflow: hidden;
}
.bc {
    background-color: #FFFFFF;
}
</style>
